

html{
    scroll-behavior: smooth;
    cursor: url(/src/assets/images/rocketship.png),auto;
}
body{
    background-image: url(./assets/images/background5.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw;
}

section{
    padding: 6rem 3rem 0;
}