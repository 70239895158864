@use '../../styles/partials/mixins' as *;

#projects{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
@include desktop{
section{
padding: 6rem 6rem 0;
}
}
.image{
    width: 100%;
    height: 100%;
}

@include tablet{
.projects__container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}
}
@include desktop{
.projects__container{    
    gap: 3rem;
}
}
.projects__item{
  background-color: rgb(98, 106, 141);
  padding: 2rem 2rem 3rem;
  border-radius: 2rem;
  margin-bottom: 1.5rem;
}

.projects__item__title{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    padding-top: .5rem;
}
.projects__title{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
}

.projects__image-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .5s ease-in-out;
}

.projects__image-overlay:hover {
    opacity: 1;
}

.projects__item-image{
    position: relative;
}

.projects__description{
    font-size: 1rem;
    width: 100%;
    text-align: center;
}

@include tablet{
.projects__description{
    font-size: 1.5rem;
    width: 50%;
}
}

