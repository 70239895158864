form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    padding: 1.5rem;
    border-radius: 8px;
    border: 2px solid white;
    resize: none;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    background-color: #dff2ff;
    color: black;
}

.contact__title{
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #1a87cf;
}

#contact{
   max-width: 600px;
   margin: 0 auto;
}

.links{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact__info{
   padding: 2rem 0;
}
.contact__icon{
    height: 2rem;
    padding-right: 1rem;
}