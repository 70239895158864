@use '../../styles/partials/reset' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;
body{
    font-family: 'Open Sans', sans-serif;
    background-color: rgba(0,0,0,0.4);
}

.nav{
    background-color:black;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 8rem;
    position: sticky;
    top: 0;
    z-index: 1;
}

.nav__header{
    color: #0e3742;
    text-transform: uppercase;
    justify-self: start;
    margin-left: 1.25rem;
    font-size: 1.5rem;
    cursor: pointer;
    width: 100%;
    -webkit-box-reflect: below 1px linear-gradient(transparent,#0008);
    animation: glow 5s linear infinite ;
    outline: none;
}
@keyframes glow{
    0%,18%,20%,50.1%,60%,65.1%,80%,90.1%,92%{
        color: #0e3742;
        box-shadow: none;
    }
    18.1%,20.1%, 30%,50%,60.1%,65%,80.1%,90%,92.1%,100%{
        color: #fff;
        text-shadow: 0 0 10px #03bcf4,
        0 0 20px #03bcf4,
        0 0 40px #03bcf4,
        0 0 80px #03bcf4,
        0 0 160px #03bcf4;
    }
    }

.nav__icon{
    color: #fff;
    display: none;
}
.nav__link{
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}
.nav__link:hover{
    background-color: #1a87cf;
    border-radius: 4px;
    transition: all 0.3s ease-out;
}

.nav__list{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: .625rem;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 1.25rem;
}

@media screen and (max-width: 768px){
   
    .nav__list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav__list.active{
        left: 0;
        background-color: #1a87cf;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav__link{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
    .nav__link:hover{
        background-color: #1a87cf;
        color: #fff;
        border-radius: 0;
    }
    .nav__header{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 100%);
        width: 40%;
    }
    .nav__icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    
}