@use '../../styles/partials/mixins' as *;

#about{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@include tablet{
    #about{
        height: 85vh;
    }
}
.about__container{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@include tablet{
    .about__container{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .about__image{
        width: 50%;
    }
    .about__bio{
        width: 50%;
    }
}
.about__image{
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about__title{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.about__bio{
    background-color: #626a8d;
    padding: 2rem;
    border-radius: 2rem;
    line-height: 1.5rem;
}
