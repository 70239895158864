#skills{
    color: white;
    padding: 0 3rem;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 6rem;
    
}
.skills__container{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.skills__title{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
}
.skills__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1s;
}
.skills__image:hover{
    transform: scale(1.3);
    z-index: 2;
}
.github{
    background-color: white;
}