@use "./variables" as *;

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin pageHeader {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem;

  @include tablet {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@mixin subHeader {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;

  @include tablet {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

//labels links and buttons

@mixin label {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.25rem;

  @include tablet {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

@mixin tableHeader {
  font-size: 0.6875rem;
  line-height: 1rem;

  @include tablet {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

@mixin bodyLarge {
  font-size: 0.9375rem;
  line-height: 1.625rem;
  font-weight: 400;
  @include tablet {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

@mixin bodyMedium {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 400;
  @include tablet {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

// @mixin bodySmall {
//   font-size: 0.6875rem;
//   line-height: 1rem;
//   font-weight: 400;
//   @include tablet {
//     font-size: 0.75rem;
//     line-height: 1.125rem;
//   }
// }

// @mixin button-formfield {
//   height: 2.25rem;
//   width: 100%;
//   border-radius: 1.25rem;
//   @include tablet {
//     height: 2.375rem;
//     padding: 0rem 1rem;
//   }
// }
